import { SALESFORCE_API_URL } from '../config';
import { ExtendedError } from '../error';
import { tryParseDeep, camelCaseObjectKeys } from '..';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export class Client {
  static API_URL = SALESFORCE_API_URL;

  constructor(auth0Token) {
    const { idToken, accessToken } = auth0Token;

    if (!idToken) {
      throw new Error('idToken missing');
    }

    if (!accessToken) {
      throw new Error('accessToken missing');
    }

    this.idToken = idToken;
    this.accessToken = accessToken;
  }

  async callAPI(options = {}) {
    const { endpoint, payload, method = 'GET', headers = {}, ...rest } = options;
    const url = Client.API_URL + endpoint;
    const fetchOptions = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-Token-Id': this.idToken,
        'X-Token-Access': this.accessToken,
        ...headers,
      },
      body: JSON.stringify(payload),
      ...rest,
    };

    const response = await fetch(url, fetchOptions);
    let data = await response.json();

    if (typeof data === 'string') {
      data = data.replace(/^.+\w\W?(?={)/m, '');
    }

    // Convert string to object-like
    const parsedData = camelCaseObjectKeys(tryParseDeep(data));

    if (!response.ok) {
      throw new ExtendedError({
        response,
        code: 'salesforce_http_error',
        message: 'Failed to fetch Salesforce API',
        status: response.status,
        statusText: response.statusText,
        body: parsedData,
      });
    }

    return parsedData;
  }

  createBusiness(data = {}) {
    const endpoint = '/v1/business';
    const options = {
      endpoint,
      method: 'POST',
      payload: data,
    };

    return this.callAPI(options);
  }

  createOrUpdateBusiness(data = {}) {
    const endpoint = '/v1/business';
    const options = {
      endpoint,
      method: 'PUT',
      payload: data,
    };

    return this.callAPI(options);
  }

  createOrUpdatePractitioner(data = {}) {
    const endpoint = '/v1/practitioner';
    const options = {
      endpoint,
      method: 'PUT',
      payload: data,
    };

    return this.callAPI(options);
  }

  createExpressPractitioner(data = {}) {
    const endpoint = '/v1/practitioner_express';
    const options = {
      endpoint,
      method: 'POST',
      payload: data,
    };

    return this.callAPI(options);
  }

  async createOrUpdateLicense(file) {
    const endpoint = '/v1/business/documents/license';
    const fileBlob = await getBase64(file);
    const options = {
      endpoint,
      method: 'PUT',
      body: fileBlob,
    };

    return this.callAPI(options);
  }

  async createOrUpdateCertificate(file) {
    const endpoint = '/v1/business/documents/reseller_certificate';
    const fileBlob = await getBase64(file);
    const options = {
      endpoint,
      method: 'PUT',
      body: fileBlob,
    };

    return this.callAPI(options);
  }

  getCurrentPractitioner() {
    const endpoint = '/v1/practitioner';
    return this.callAPI({ endpoint });
  }

  getCurrentBusiness() {
    const endpoint = '/v1/business';
    return this.callAPI({ endpoint });
  }

  getCurrentDocumentStatus() {
    const endpoint = '/v1/business/documents/status';
    return this.callAPI({ endpoint });
  }

  getCurrentMapPolicy() {
    const endpoint = '/v1/business/map_policy';
    return this.callAPI({ endpoint });
  }

  createOrUpdateMapPolicy(data = {}) {
    const endpoint = '/v1/business/map_policy';
    const options = {
      endpoint,
      method: 'PUT',
      payload: data,
    };

    return this.callAPI(options);
  }
}
