/* eslint-disable no-console */

import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import CartFlyout from '../../components/CartFlyout';
import Footer from '../Footer';
import Header from '../Header';
import VerificationModal from '../../components/RegisterAccount/VerificationModal';
import { PageSpinner } from '../../components/Spinner';
import { AuthContext } from '../../context/auth-context';
import { StoreContext } from '../../context/store-context';
import { gidUrlToId, normalize } from '../../utils';
import { PRACTITIONER_STATUS } from '../../data/constants';

const ns = `layout-default`;

const DefaultLayout = ({ children, location }) => {
	const { pathname } = location;
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isVerifying, setIsVerifying] = useState(false);
	const [isVerificationAlertVisible, setIsVerificationAlertVisible] = useState(false);
	const { elevarUserDataEvent, cartLoading } = React.useContext(StoreContext);
	const { state: authState, actions: authActions } = useContext(AuthContext);
	const {
		isInitialized,
		isInitializing,
		practitioner,
		auth,
		userPropertiesLoaded,
		shopifyCustomer,
	} = authState;
	const isActiveExpress = practitioner?.status === PRACTITIONER_STATUS.ACTIVE_EXPRESS;
	const numberOfOrders = shopifyCustomer?.orders?.length;
	const isLoading = isInitializing || !isInitialized;

	const handleRequestClose = () => {
		setIsModalOpen(false);
		setIsVerificationAlertVisible(false);
		setIsVerifying(false);
	};

	const handleVerificationAction = async () => {
		try {
			setIsVerifying(true);
			setIsVerificationAlertVisible(false);
			const profile = await authActions.getAuth0Profile(true);

			if (
				(!profile.emailVerified && !isActiveExpress) ||
				(!profile.emailVerified && isActiveExpress && numberOfOrders > 0)
			) {
				setIsVerificationAlertVisible(true);
			} else {
				setIsModalOpen(false);
			}
		} catch (error) {
			console.error(error.json || error);
		} finally {
			setIsVerifying(false);
		}
	};

	const handleSignOutAction = () => {
		authActions.signOut();
		handleRequestClose();
	};

	const spinnerMarkup = isLoading && <PageSpinner />;
	const contentMarkup = !isLoading && children;

	const modalMarkup =
		!pathname.includes('/account/register') &&
		!pathname.includes('/nordic-live') &&
		!isLoading &&
		isModalOpen && (
			<VerificationModal
				isOpen={isModalOpen}
				isVerifying={isVerifying}
				isVerificationAlertVisible={isVerificationAlertVisible}
				onVerificationAction={handleVerificationAction}
				onSignOutAction={handleSignOutAction}
				onRequestClose={handleRequestClose}
				modalProps={{
					shouldHideCloseButton: true,
					shouldCloseOnEsc: false,
					shouldCloseOnOverlayClick: false,
				}}
			/>
		);

	useEffect(() => {
		const emailVerified = async () => {
			const isLocalEmailVerified = auth.profile.emailVerified;

			if (!isLocalEmailVerified) {
				const dbProfile = await authActions.getAuth0Profile(true);
				return !!dbProfile.emailVerified;
			}

			return !!isLocalEmailVerified;
		};

		(async () => {
			if (auth.isAuthenticated) {
				try {
					const isEmailVerified = await emailVerified();

					if (
						(!isEmailVerified && !isActiveExpress) ||
						(!isEmailVerified && isActiveExpress && numberOfOrders > 0)
					) {
						setIsModalOpen(true);
					} else {
						handleRequestClose();
					}
				} catch (error) {
					console.error(error.json || error);
					handleRequestClose();
				}
			} else {
				handleRequestClose();
			}
		})();
	}, [auth.isAuthenticated, auth.profile.emailVerified]);

	const elevarScriptContent = `
    try {
      const settings = {};
      const config = (await import("${process.env.GATSBY_ELEVAR_CONFIG_URL}")).default;
      const scriptUrl = settings.proxyPath
        ? \`\${settings.proxyPath}\${config.script_src_custom_pages_proxied}\`
        : config.script_src_custom_pages;

      if (scriptUrl) {
        const { handler } = await import(scriptUrl);
        await handler(config, settings);
      }
    } catch (error) {
      console.error("Elevar Error:", error);
    }
  `;

	useEffect(() => {
		if (userPropertiesLoaded && !cartLoading) {
			elevarUserDataEvent(location);
		}
	}, [userPropertiesLoaded, cartLoading]);

	return (
		<>
			<Helmet>
				<script type="module">{elevarScriptContent}</script>
				{/* OneTrust Cookies Consent Notice start */}
				{/* eslint-disable-next-line react/no-unknown-property */}
				<script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charSet="UTF-8" data-domain-script={process.env.GATSBY_ONE_TRUST_DATA_DOMAIN_SCRIPT} />
				<script type="text/javascript">{`function OptanonWrapper() { }`}</script>
				{/* OneTrust Cookies Consent Notice end */}
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
			</Helmet>
			<div className={rootClassnames}>
				<Header pathname={pathname} location={location} />
				<main>
					{spinnerMarkup}
					{contentMarkup}
					{modalMarkup}
				</main>
				<Footer pathname={pathname} location={location} />
			</div>
			<CartFlyout location={location} />
		</>
	);
};

export default DefaultLayout;
