import React, { useContext } from 'react';
import MarkdownAnchor from '../MarkdownAnchor';
import { AuthContext } from '../../context/auth-context';
import { PRACTITIONER_STATUS, PRACTITIONER_STATUS_MESSAGE } from '../../data/constants';

const PractitionerStatusMessage = props => {
  const { variant = 'long', ...rest } = props;
  const { state: authState } = useContext(AuthContext);
  const { practitioner, shopifyCustomer } = authState;
  const numberOfOrders = shopifyCustomer?.orders?.length;
  const status = practitioner.status || '';
  const isActiveExpress = status === PRACTITIONER_STATUS.ACTIVE_EXPRESS;
  const MESSAGE = PRACTITIONER_STATUS_MESSAGE[status.toUpperCase()];

  if (!MESSAGE) {
    return null;
  }

  if (isActiveExpress && numberOfOrders > 0) {
    return (
      <MarkdownAnchor
        text={PRACTITIONER_STATUS_MESSAGE.INCOMPLETE.LONG}
        {...rest}
      />
    );
  }

  return (
    <MarkdownAnchor
      text={MESSAGE[variant.toUpperCase()] || MESSAGE.LONG}
      {...rest}
    />
  );
};

export default PractitionerStatusMessage;
